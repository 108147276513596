import { IS_SSR } from 'ots-constants';
import { useEffect, useState } from 'react';

export const useVisibilityWindow = (ref: React.RefObject<HTMLElement>, stickToTrue: boolean = false, waitScroll: boolean = false, threshold: number = 150) => {
  const [visible, setVisible] = useState<boolean>(false);
  useEffect(() => {
    if (!IS_SSR) {
      const check = () => {
        if (ref.current) {
          const pos = ref.current.getBoundingClientRect();
          const topEdgeIn = pos.y > -threshold && pos.y < window.innerHeight + threshold;
          const bottomEdgeIn = pos.y + pos.height > -threshold && pos.y + pos.height < window.innerHeight + threshold;
          const isVisible = topEdgeIn || bottomEdgeIn;
          if (stickToTrue) {
            setVisible((v) => v || isVisible);
          } else {
            setVisible(isVisible);
          }
        } else {
          setVisible((now) => {
            if (now && !stickToTrue) {
              return false;
            }
            return now;
          });
        }
      };
      window.addEventListener('scroll', check);
      window.addEventListener('resize', check);

      if (!waitScroll) {
        check();
      }
      return () => {
        window.removeEventListener('scroll', check);
        window.removeEventListener('resize', check);
      };
    }
    return undefined;
  }, [setVisible]);

  return visible;
};
