import React from 'react';
import { ReactComponent as SvgStarFull } from '../../../../public/assets/icons/star-full.svg';
import { ReactComponent as SvgStarHalf } from '../../../../public/assets/icons/star-half.svg';
import { ReactComponent as SvgStarEmpty } from '../../../../public/assets/icons/star-empty.svg';

export const Stars = ({
  amount, title, className,
}: {
  amount: number, title?: string, className?: string
}) => {
  const roundAmount = Math.round(amount * 2) / 2; // checking if amount values are close to greater number than to lower one
  const fullStars = Math.floor(roundAmount);
  const halfStars = (amount - fullStars >= 0.3) ? 1 : 0;
  const grayStars = 5 - fullStars - halfStars;

  const nodes: Array<React.ReactNode> = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < fullStars; i++) {
    nodes.push(<SvgStarFull key={`${i}f`} />);
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < halfStars; i++) {
    nodes.push(<SvgStarHalf key={`${i}h`} />);
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < grayStars; i++) {
    nodes.push(<SvgStarEmpty key={`${i}e`} />);
  }

  return (
    <div title={title} className={className}>
      {nodes}
    </div>
  );
};
