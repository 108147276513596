import { useOTSTranslation } from 'i18n/useOTSTranslation';
import styles from './styles.module.scss';

interface ILoadMoreButtonProp {
  onClick: () => void
}

const LoadMoreButton = ({ onClick }: ILoadMoreButtonProp) => {
  const { t } = useOTSTranslation('common');

  return (
    <button
      type="button"
      className={styles.loadMore}
      onClick={onClick}
    >
      {t('common.loadMore')}
    </button>
  );
};

export default LoadMoreButton;
