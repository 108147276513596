import { IWithChildren } from 'types';
import styles from './styles.module.scss';

export function Main({
  children,
}: { } & IWithChildren) {
  return (
    <div className={styles.main}>
      {children}
    </div>
  );
}
