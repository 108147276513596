import classNames from 'classnames';
import { ResortTopRated } from 'api/home/types';
import styles from './styles.module.scss';

export const Tab = ({
  title,
  activeTab,
  tab,
  setActiveTab,
}: {
  title: string,
  activeTab?: boolean,
  tab: ResortTopRated,
  setActiveTab: (tab: ResortTopRated) => void;
}) => (
  <button
    type="button"
    className={classNames(styles.tab, activeTab ? styles.active : '')}
    onClick={() => setActiveTab(tab)}
  >
    {title}
  </button>
);
