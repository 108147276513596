import classNames from 'classnames';
import { AdUnit, AdUnitClasses } from 'googlead/components/AdUnit';
import { useAdUnitContext } from 'googlead/components/AdUnitContext';
import { GPTAds } from 'googlead/GPTAds';
import { AdPositions } from 'googlead/types';
import { isProductionServer } from 'ots-constants';
import React, { useEffect, useRef, useState } from 'react';
import { SizeMonitor } from 'components/size/SizeMonitor';
import styles from './adunit.module.scss';

export const RegularAd = ({ wrapperClassName, className, isFirst }: { wrapperClassName?: string, className?: string, isFirst?: boolean }) => {
  const [active, setActive] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const { registerCandidate, unRegisterCandidate } = useAdUnitContext();

  useEffect(() => {
    const id = registerCandidate(ref, setActive);
    return () => unRegisterCandidate(id);
  }, []);

  const adUnit = isFirst ? AdPositions.RegularFirstOnPage : AdPositions.Regular;

  return (
    <div
      data-adunit={adUnit}
      className={classNames(wrapperClassName, styles.adMonitorWrapper, active ? styles.active : styles.inactive, isProductionServer && styles.production)}
    >
      <SizeMonitor>
        <div
          ref={ref}
          className={classNames(styles.adMonitor, active ? styles.active : styles.inactive, isProductionServer && styles.production)}
          title={active ? 'Debug: Advertisement is requested here' : 'Debug: Advertisement is ignored here'}
        />
        {active && (
          <AdUnit
            generator={GPTAds[adUnit]}
            className={classNames(className, AdUnitClasses.infinite)}
          />
        )}
      </SizeMonitor>
    </div>
  );
};
