import { useOTSTranslation } from 'i18n/useOTSTranslation';
import {
  FormEvent, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import { SearchPopup } from 'components/home/SearchPopup';
import { useAnalytics } from 'util/analytics';
import { setFocusToSearchInput } from 'util/setFocusToInputByQuery';
import styles from './styles.module.scss';

export const Search = ({
  className,
  placeholder,
  searchQuery,
  clearSearchQuery,
}: {
  className?: string,
  placeholder?: string,
  searchQuery?: string | string[] | undefined,
  clearSearchQuery?: () => void,
}) => {
  const { t } = useOTSTranslation('common');
  const a = useAnalytics();
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false);

  const toggleSearch = useCallback(() => {
    new Promise<void>((resolve) => {
      if (clearSearchQuery) {
        clearSearchQuery();
      }
      setSearchOpen(!isSearchOpen);
      if (!isSearchOpen) { // Will be open
        a.searchBox();
      }
      resolve();
    }).then(() => {
      setFocusToSearchInput();
    });
  }, [isSearchOpen]);

  useEffect(() => {
    setSearchOpen(!!searchQuery);
  }, [searchQuery]);

  const handleSubmit = useCallback((e:FormEvent<HTMLFormElement> | undefined) => e?.preventDefault(), []);

  const handleFocusSearch = useCallback((e:React.FocusEvent<HTMLInputElement> | undefined) => e?.target.blur(), []);

  return (
    <>
      {isSearchOpen && (
        <SearchPopup
          closePopup={toggleSearch}
          query={searchQuery}
        />
      )}
      <form
        className={classNames(styles.search, className)}
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          placeholder={!placeholder ? t('homePage.searchBarHelpText') : placeholder}
          onClick={toggleSearch}
          onFocus={handleFocusSearch}
        />
        <button
          type="submit"
          className={styles.btnSearch}
          onClick={toggleSearch}
        >
          {t('common.searchButton')}
        </button>
      </form>
    </>
  );
};
