import { axiosInstance } from 'api/axiosInstance';
import { staticRoutes } from 'api/apiRoutes';
import {
  IBackgroundImages, ICategoryNews,
  INews, INewsFromCategory,
  IPopularSearch,
  IGuideHomeTopRated,
  IResortTopRated,
  ISearchResult,
  ISnowWebcam,
  ResortTopRated,
  ISearchNewsResult,
} from 'api/home/types';
import { INearbyResort, IRegionAllPathsInfo, IRegionFeaturedResort } from 'api/region/types';
import { SortingType } from 'components/common/Grid/types';

export const fetchSearch = async (query: string) => (
  await axiosInstance().get<ISearchResult>(staticRoutes.search(query))
).data;

export const fetchPopularSearch = async () => (
  await axiosInstance().get<IPopularSearch>(staticRoutes.searchPopular())
).data;

export const fetchBackgroundImages = async () => {
  const day = Math.floor(Date.now() / (24 * 60 * 60 * 1000));
  const index = day % 10;
  return {
    image: (index < 9) ? `/home/landing-0${index + 1}.jpg` : '/home/landing-10.jpg',
  } as IBackgroundImages;
};

export const fetchSnowWebcams = async (limit: number) => (
  await axiosInstance(String(limit)).get<{ data: Array<ISnowWebcam> }>(staticRoutes.snowWebcams(limit))
).data.data;

export const fetchResortTopRating = async (type: ResortTopRated) => (
  await axiosInstance(type).get<{ data: Array<IResortTopRated> }>(staticRoutes.resortTopRating(type))
).data.data;

export const fetchLastNews = async () => (
  await axiosInstance().get<Array<INews>>(staticRoutes.lastNews())
).data;

export const fetchLastNewsWeather = async () => (
  await axiosInstance().get<Array<INews>>(staticRoutes.lastNewsWeather())
).data;

export const fetchGuideHomeTopRatedResorts = async (type: ResortTopRated, page: number, limit?: number) => (
  await axiosInstance(`${type}:${page}`).get<IGuideHomeTopRated>(staticRoutes.resortTopRatingPages(type, page, limit))
).data;

export const fetchRegionsListGuide = async () => (
  await axiosInstance().get<IRegionAllPathsInfo>(staticRoutes.regionsListGuide())
).data;

type IRawResortId = { id: string };

export const fetchNearbyResorts = async (lat?: number, lon?: number, orderBy?: string,
  direction?: SortingType) => {
  if (typeof lat === 'undefined' || typeof lon === 'undefined') {
    return [];
  }
  const idRaw = (await axiosInstance().get<IRawResortId[]>(staticRoutes.nearbyResorts(lat, lon, orderBy, direction))).data;
  const ids = idRaw.filter((r) => !!r.id).map((rawResort: IRawResortId) => (rawResort.id.toString()));
  // ['617', '675', '1374', '448', '229', '230', '231', '232', '233', '3060', '3059'];
  if (!ids || ids.length === 0) {
    return [];
  }
  const resorts = (await axiosInstance().get<INearbyResort[]>(staticRoutes.getMultipleResorts(ids))).data;
  return resorts.sort((r1, r2) => ids.indexOf(r1.uuid) - ids.indexOf(r2.uuid));
};

export const fetchGuideHomeFeatured = async () => (
  await axiosInstance().get<IRegionFeaturedResort>(staticRoutes.guideHomeFeatured())
).data;

export const fetchSnowWeatherHomeFeatured = async () => (
  await axiosInstance().get<IRegionFeaturedResort>(staticRoutes.snowWeatherHomeFeatured())
).data;

export const fetchNewsCategories = async () => (
  axiosInstance().get<Array<ICategoryNews>>(staticRoutes.newsCategoriesList())
);

export const fetchPopularNews = async () => (
  await axiosInstance().get<Array<INews>>(staticRoutes.popularNews())
).data;

export const fetchNewsFeatured = async () => (
  await axiosInstance().get<Array<INews>>(staticRoutes.newsFeatured())
).data;

export const fetchNewsPromCategory = async (category: string, page: number) => (
  await axiosInstance(String(page)).get<INewsFromCategory>(staticRoutes.newsFromCategory(category, page))
).data;

export const fetchNewsSearch = async (query: string) => (
  await axiosInstance().get<ISearchNewsResult>(staticRoutes.searchNews(query))
).data;
