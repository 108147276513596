import classNames from 'classnames';
import { Search } from 'components/home/Search';
import { IBackgroundImages } from 'api/home/types';
import styles from './styles.module.scss';

interface IHero400Props {
  backgroundImage?: IBackgroundImages
  light?: boolean
}

export const Hero400Error = ({ backgroundImage, light }: IHero400Props) => (
  <div className={styles.content}>
    <header className={styles.photo} style={{ backgroundImage: `url("${backgroundImage?.image}"` }}>
      <div className={classNames(styles.background, { [styles.lightBackground]: !!light })} />
      <div className={classNames('container', styles.container)}>
        <h1 className={classNames(styles.title, { [styles.fontBlack]: !!light })}>Data Error</h1>
        <h2 className={classNames(styles.description, { [styles.fontBlack]: !!light })}>
          Something went wrong, please contact us with page URL that happened on
        </h2>
        <Search className={styles.search} />
      </div>
    </header>
  </div>
);
