import { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'components/Spinner';
import LoadMoreButton from 'components/home/BestResorts/LoadMoreButton';
import { ResortInfo } from 'components/home/BestResorts/ResortInfo';
import { isValidResponse } from 'util/isValidResponse';
import { Diagnostics } from 'util/diagnostics';
import { IGuideHomeTopRated, IResortTopRated, ResortTopRated } from 'api/home/types';
import { fetchGuideHomeTopRatedResorts } from 'api/home/static';
import { IPaginationResponse } from 'api/resort/types';
import styles from './styles.module.scss';

const DEBUG_TAG_GUIDE: string = 'homepage-best-resorts';

export const GuideBestResorts = ({
  resortTopRatingOverall,
  activeTab,
}: {
  resortTopRatingOverall: IGuideHomeTopRated,
  activeTab: ResortTopRated,
}) => {
  const [resortsList, updateResortList] = useState<Array<IResortTopRated>>(resortTopRatingOverall.data);
  const [pagination, updatePagination] = useState<IPaginationResponse>(resortTopRatingOverall.pagination);
  const [isLoadingPage, toggleLoadingPage] = useState<boolean>(false);
  const hasMoreRows = resortsList.length < pagination.count;

  const checkValidResponse = (response: IGuideHomeTopRated) => (
    response.pagination && response.data.every((item) => isValidResponse(item, ['resort', 'resortPath']))
  );

  const loadNewResortPage = useCallback(async () => {
    toggleLoadingPage(true);
    const nextPage = Math.ceil((resortsList.length / 10));
    const response = await fetchGuideHomeTopRatedResorts(activeTab, nextPage);
    const newList = [...resortsList, ...response.data];

    if (!checkValidResponse(response)) {
      Diagnostics.message({ message: 'Invalid data received', tag: DEBUG_TAG_GUIDE });
      return;
    }

    updateResortList(newList);
    updatePagination(response.pagination);
    toggleLoadingPage(false);
  }, [resortsList, activeTab]);

  const loadNewResorts = useCallback(async () => {
    toggleLoadingPage(true);
    const response = await fetchGuideHomeTopRatedResorts(activeTab, 0);
    const newList = response.data;

    if (!checkValidResponse(response)) {
      Diagnostics.message({ message: 'Invalid data received', tag: DEBUG_TAG_GUIDE });
      return;
    }

    updateResortList(newList);
    updatePagination(response.pagination);
    toggleLoadingPage(false);
  }, [resortsList, activeTab]);

  useEffect(() => {
    loadNewResorts();
  }, [activeTab]);

  return (
    <>
      <div className={styles.resortList}>
        <div className={isLoadingPage ? styles.isLoading : ''}>
          {
            resortsList.map((i, index) => (
              <ResortInfo
                isLoading={isLoadingPage}
                key={i.resort.uuid}
                index={index + 1}
                data={i}
              />
            ))
          }
        </div>
      </div>
      {hasMoreRows && !isLoadingPage && (<LoadMoreButton onClick={loadNewResortPage} />)}
      {
        isLoadingPage && (
          <div className={styles.boxLoader}>
            <Spinner />
          </div>
        )
      }
    </>
  );
};
