import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { Tab } from 'components/home/BestResorts/Tab';
import React, { useCallback, useState } from 'react';
import { IGuideHomeTopRated, IResortTopRated, ResortTopRated } from 'api/home/types';
import { DescriptionCategory } from 'components/home/BestResorts/DescriptionCategory';
import { HomeBestResorts } from 'components/home/BestResorts/HomeBestResorts';
import { GuideBestResorts } from 'components/home/BestResorts/GuideBestResorts';
import { useAnalytics } from 'util/analytics';
import classNames from 'classnames';
import { BlockContent } from 'components/blocks/BlockContent';
import { BoxWithShadow } from 'components/blocks/BoxWithShadow';
import { useSanitizedHtmlProps } from 'util/sanitized';
import styles from './styles.module.scss';

export const BestResorts = ({
  resortTopRatingOverall,
  regionUUID,
  pageType,
  isFullWidth,
}: {
  resortTopRatingOverall: Array<IResortTopRated> | IGuideHomeTopRated,
  regionUUID?: string,
  regionTitle?: string,
  pageType?: 'global' | undefined,
  isFullWidth?: boolean,
}) => {
  const { t } = useOTSTranslation('common');
  const [activeTab, setActiveTab] = useState<ResortTopRated>(ResortTopRated.overall);
  const tabs: Record<ResortTopRated, string> = {
    overall: t('region.resortGuide.overallTitle'),
    allMtnTerrain: t('region.resortGuide.allMtnTerrainTitle'),
    familyFriendly: t('region.resortGuide.familyFriendlyTitle'),
    terrainPark: t('region.resortGuide.terrainParkTitle'),
    beginner: t('region.resortGuide.beginnerTitle'),
    apresSki: t('region.resortGuide.apresSkiTitle'),
    smallSkiArea: t('region.resortGuide.smallSkiAreaTitle'),
    intermediate: t('region.resortGuide.intermediateTitle'),
    expert: t('region.resortGuide.expertTitle'),
  };

  const a = useAnalytics();

  const isGuideHomePage = !Array.isArray(resortTopRatingOverall);

  const setActiveCategory = useCallback((cat: ResortTopRated) => {
    a.reviewCategory({ content: tabs[cat] });
    setActiveTab(cat);
  }, [a, setActiveTab]);

  const sanitized = useSanitizedHtmlProps(t('homePage.topRated'));

  return (
    <section className={classNames(styles.bestResorts, 'mt-4')}>
      <h2>{t('global.resortGuide.TopRatedResorts')}</h2>
      <BoxWithShadow className="mb-4">
        <BlockContent>
          <div {...sanitized} />
        </BlockContent>
      </BoxWithShadow>
      <div className={classNames(styles.controlTabs, isFullWidth && styles.isFull)}>
        {
          Object.entries(tabs).map(([key, value]) => (
            <Tab
              key={key}
              title={value}
              activeTab={key === activeTab}
              tab={key as ResortTopRated}
              setActiveTab={setActiveCategory}
            />
          ))
        }
      </div>
      <DescriptionCategory tab={activeTab} title={tabs[activeTab]} />
      {!isGuideHomePage && (
        <HomeBestResorts
          resortTopRatingOverall={resortTopRatingOverall as Array<IResortTopRated>}
          activeTab={activeTab}
          regionUUID={regionUUID}
          pageType={pageType}
        />
      )}
      {isGuideHomePage && (
        <GuideBestResorts
          resortTopRatingOverall={resortTopRatingOverall as IGuideHomeTopRated}
          activeTab={activeTab}
        />
      )}
    </section>
  );
};
