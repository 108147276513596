import { IResortTopRated, ResortTopRated } from 'api/home/types';
import { RegularAd } from 'googlead/components/RegularAd';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { isValidResponse } from 'util/isValidResponse';
import { fetchResortTopRating } from 'api/home/static';
import { fetchRegionResortTopRating } from 'api/region/static';
import { Diagnostics } from 'util/diagnostics';
import { ResortInfo } from 'components/home/BestResorts/ResortInfo';
import classNames from 'classnames';
import styles from './styles.module.scss';

const DEBUG_TAG_HOME: string = 'homepage-best-resorts';

export const HomeBestResorts = ({
  resortTopRatingOverall,
  activeTab,
  regionUUID,
  pageType,
}: {
  resortTopRatingOverall: Array<IResortTopRated>,
  activeTab: ResortTopRated,
  regionUUID?: string,
  pageType?: 'global' | undefined,
}) => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [resortTopRating, setResortTopRating] = useState<Array<IResortTopRated>>(resortTopRatingOverall);

  useEffect(() => {
    setResortTopRating(resortTopRatingOverall);
  }, [resortTopRatingOverall]);

  const checkValidResponse = (response: Array<IResortTopRated>) => (
    response.every((item) => isValidResponse(item, ['resort', 'resortPath']))
  );

  const loadNewResortList = useCallback(async () => {
    toggleLoading(true);
    const response = regionUUID ? (
      await fetchRegionResortTopRating(regionUUID, activeTab)
    ) : (
      await fetchResortTopRating(activeTab)
    );

    if (!checkValidResponse(response)) {
      Diagnostics.message({ message: 'Invalid data received', tag: DEBUG_TAG_HOME });
      return;
    }

    setResortTopRating(response);
    toggleLoading(false);
  }, [activeTab, setResortTopRating, regionUUID]);

  const showResortsList = useMemo(() => (
    resortTopRating.map((item, i) => (
      <React.Fragment key={item.resort.uuid}>
        <ResortInfo
          isLoading={isLoading}
          index={i + 1}
          data={item}
        />
        {
          pageType !== 'global' && !regionUUID && i === 2 && (
            <RegularAd
              className={styles.unit}
              wrapperClassName={styles.wrapperAdBlock}
            />
          )
        }
        {
          pageType !== 'global' && regionUUID && (i === 3 || i === 9) && (
            <RegularAd
              className={styles.unit}
              wrapperClassName={styles.wrapperAdBlock}
            />
          )
        }
      </React.Fragment>
    ))
  ), [resortTopRatingOverall, activeTab, regionUUID, isLoading]);

  useEffect(() => {
    loadNewResortList();
  }, [activeTab]);

  return (
    <div className={classNames(styles.resortList, regionUUID && styles.w50)}>
      <div className={isLoading ? styles.isLoading : ''}>
        {showResortsList}
      </div>
    </div>
  );
};
