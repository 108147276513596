import { useOTSTranslation } from 'i18n/useOTSTranslation';
import { ResortTopRated } from 'api/home/types';
import { ReactComponent as SvgOverall } from 'public/assets/rated/overall.svg';
import { ReactComponent as SvgApresSki } from 'public/assets/rated/apresSki.svg';
import { ReactComponent as SvgBeginner } from 'public/assets/rated/beginner.svg';
import { ReactComponent as SvgExpert } from 'public/assets/rated/expert.svg';
import { ReactComponent as SvgFamilyFriendly } from 'public/assets/rated/familyFriendly.svg';
import { ReactComponent as SvgIntermediate } from 'public/assets/rated/intermediate.svg';
import { ReactComponent as SvgSmallSkiArea } from 'public/assets/rated/smallSkiArea.svg';
import { ReactComponent as SvgAllMtnTerrain } from 'public/assets/rated/allMtnTerrain.svg';
import { ReactComponent as SvgTerrainPark } from 'public/assets/rated/terrainPark.svg';
import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import styles from './styles.module.scss';

export const DescriptionCategory = ({
  tab,
  title,
}: {
  tab: ResortTopRated,
  title: string
}) => {
  const { t } = useOTSTranslation('common');

  const showDescription = () => {
    switch (tab) {
      case ResortTopRated.overall: {
        return t('region.resortGuide.overallDescription');
      }
      case ResortTopRated.allMtnTerrain: {
        return t('region.resortGuide.allMtnTerrainDescription');
      }
      case ResortTopRated.familyFriendly: {
        return t('region.resortGuide.familyFriendlyDescription');
      }
      case ResortTopRated.terrainPark: {
        return t('region.resortGuide.terrainParkDescription');
      }
      case ResortTopRated.beginner: {
        return t('region.resortGuide.beginnerDescription');
      }
      case ResortTopRated.apresSki: {
        return t('region.resortGuide.apresSkiDescription');
      }
      case ResortTopRated.smallSkiArea: {
        return t('region.resortGuide.smallSkiAreaDescription');
      }
      case ResortTopRated.intermediate: {
        return t('region.resortGuide.intermediateDescription');
      }
      case ResortTopRated.expert: {
        return t('region.resortGuide.expertDescription');
      }
      default: {
        return '';
      }
    }
  };

  const showSvg = () => {
    switch (tab) {
      case ResortTopRated.overall: {
        return <SvgOverall />;
      }
      case ResortTopRated.allMtnTerrain: {
        return <SvgAllMtnTerrain />;
      }
      case ResortTopRated.familyFriendly: {
        return <SvgFamilyFriendly />;
      }
      case ResortTopRated.terrainPark: {
        return <SvgTerrainPark />;
      }
      case ResortTopRated.beginner: {
        return <SvgBeginner />;
      }
      case ResortTopRated.apresSki: {
        return <SvgApresSki />;
      }
      case ResortTopRated.smallSkiArea: {
        return <SvgSmallSkiArea />;
      }
      case ResortTopRated.intermediate: {
        return <SvgIntermediate />;
      }
      case ResortTopRated.expert: {
        return <SvgExpert />;
      }
      default: {
        return '';
      }
    }
  };

  const renderSvg = [ELocale.EN_US, ELocale.EN_GB].includes(getLocale() as ELocale);

  return (
    <div className={styles.descriptionBlock}>
      <div className={styles.text}>
        <span className={styles.title}>{title}</span>
        <p>{showDescription()}</p>
      </div>
      { renderSvg && <div className={styles.img}>{showSvg()}</div> }
    </div>
  );
};
